import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import { useAuth } from './AuthProvider';

function TimestampList() {
    const { vendor } = useParams();
    const { logs } = useAuth();
    const navigate = useNavigate();
    const timestamps = logs[vendor]

    return (
        <List sx={{ cursor: 'pointer' }}>
            {timestamps?.filter(folder => folder.timestamp !== 'assets').map(({ timestamp }) => (
                timestamp ? <ListItem
                    key={timestamp}
                    onClick={() => navigate(`/${vendor}/${timestamp}`)}
                    sx={{
                        '&:hover': {
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                        },
                    }}>
                    <ListItemText primary={timestamp} />
                </ListItem> : null
            ))}
        </List>
    );
}

export default TimestampList;
