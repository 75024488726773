import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Grid2 } from '@mui/material';
import { useAxios } from '../AxiosProvider';
import ChartPlot from './ChartPlot'; // Import the new ChartPlot component
import DeleteLogButton from './DeleteLogButton';

const ChartComponent = ({ vendor, timestamp, fileName }) => {
    const axios = useAxios();
    const [chartData, setChartData] = useState(null);
    const [imageSrc, setImageSrc] = useState(''); // To manage image source
    const [croppedSegments, setCroppedSegments] = useState({}); // Store cropped segments by timestamp

    // Layout stored in useRef to preserve legend visibility settings
    const layoutRef = useRef(null);

    // Function to parse CSV data and prepare chart traces
    const parseCsvData = useCallback((data) => {
        const rows = data.split('\n').filter(row => row); // Remove empty rows
        const headers = rows[0].split(',').map(header => header.trim());
        const df = rows.slice(1).map(row => row.split(',').reduce((acc, value, idx) => {
            acc[headers[idx]] = value.trim();
            return acc;
        }, {}));

        // Parameters to hide by default
        const hiddenParams = [
            'RC6', 'RC7', 'RC8', 'RC9', 'RC10', 'RC13', 'RC14', 'RC15', 'RC16',
            'kp_r', 'ki_r', 'kd_r', 'setpoint_r', 'sample_time_r',
            'min_r', 'max_r', 'kp_p', 'ki_p', 'kd_p',
            'setpoint_p', 'sample_time_p', 'min_p', 'max_p',
            'FPS', 'MSP', 'Mode',
            'Roll', 'Pitch', 'Yaw', 'Throttle'
        ];

        // Extract columns and prepare data for plotting
        const timeSeries = df.map(row => new Date(row.Timestamp));
        const altitude = df.map(row => parseFloat(row.Altitude));
        const otherVars = headers.filter(header => header !== 'Timestamp' && header !== 'Altitude');

        // Store cropped_segment information as a string in an object keyed by timestamp
        const segments = {};
        df.forEach(row => {
            if (row.cropped_segment) {
                segments[row.Timestamp] = row.cropped_segment; // Store cropped_segment as string
            }
        });
        setCroppedSegments(segments);

        // Prepare data traces for Plotly
        const dataTraces = [
            {
                x: timeSeries,
                y: altitude,
                type: 'scatter',
                mode: 'lines',
                name: 'Altitude',
                fill: 'tozeroy',
                line: { color: 'lightblue' },
                hoverinfo: 'x+y'
            },
            ...otherVars.map(variable => ({
                x: timeSeries,
                y: df.map(row => parseFloat(row[variable])),
                type: 'scatter',
                mode: 'lines',
                name: variable,
                visible: hiddenParams.includes(variable) ? 'legendonly' : true, // Hide certain parameters
                hoverinfo: 'x+y'
            })),
            // Adding cropped segments as blue dots on the plot
            {
                x: Object.keys(segments), // Use timestamps of cropped segments as X
                y: new Array(Object.keys(segments).length).fill(0), // Y-value is 0, just to plot on the X-axis
                type: 'scatter',
                mode: 'markers',
                name: 'Cropped Segment',
                marker: { color: 'blue', size: 10 }, // Make the dots blue and adjust their size
                hoverinfo: 'x+y'
            }
        ];

        // Save the current layout's legend visibility before updating
        const currentLegendVisibility = dataTraces.map((trace, index) => {
            const existingLayout = layoutRef.current;
            if (existingLayout && existingLayout.showlegend) {
                return existingLayout.data && existingLayout.data[index] && existingLayout.data[index].visible;
            }
            return trace.visible; // default visibility
        });

        // Set layout with vertical lines at breakpoints
        const layout = {
            title: `${vendor}/${timestamp}/${fileName}`,
            xaxis: {
                title: 'Time',
                rangeslider: { visible: true, y: -1 }, // Enable range slider
                type: 'date',
                showticklabels: true
            },
            yaxis: {
                title: 'Altitude',
                showticklabels: true,
                range: [Math.min(...altitude) - 10, 500]
            },
            yaxis2: {
                title: 'Other Variables',
                overlaying: 'y',
                side: 'right',
                showticklabels: true,
                range: [0, 2500]
            },
            legend: {
                title: { text: 'Variables' },
                orientation: 'h',    // Make legend horizontal
                xanchor: 'center',    // Center align horizontally
                yanchor: 'top',
                x: 0.5,
                y: -0.5               // Position below chart
            },
            plot_bgcolor: '#e5ecf6',
            margin: { l: 50, r: 50, t: 50, b: 150 }, // Adjust margins to fit the horizontal legend
        };

        // Store the layout in the ref and apply the visibility state to the traces
        layoutRef.current = layout;

        // Apply the saved visibility state to the new traces
        dataTraces.forEach((trace, index) => {
            trace.visible = currentLegendVisibility[index]; // Restore visibility of traces
        });

        // Update chart data
        setChartData({
            data: dataTraces,
            layout: layoutRef.current, // Keep the previous layout intact
        });
    }, [vendor, timestamp, fileName]); // Dependency on fileName

    useEffect(() => {
        if (fileName) {
            // Fetch CSV data for the selected file
            axios.get(`/logs/${vendor}/${timestamp}/${fileName}`)
                .then(response => {
                    parseCsvData(response.data);
                })
                .catch(error => console.error('Error fetching CSV data:', error));
        }
    }, [vendor, timestamp, fileName, axios, parseCsvData]); // Now includes parseCsvData to avoid warnings

    useEffect(() => {
        // Initialize WebSocket connection
        const socket = new WebSocket(process.env.REACT_APP_API_URL); // Your WebSocket server address

        socket.onopen = () => {
            // console.log('WebSocket connected');
            socket.send(JSON.stringify({ type: 'subscribe', vendor, timestamp, fileName }));
        };

        socket.onmessage = (event) => {
            const message = JSON.parse(event.data);
            if (message.type === 'fileUpdate' && message.fileName === fileName) {
                // If the file is updated, fetch the file data again
                axios.get(`/logs/${vendor}/${timestamp}/${fileName}`)
                    .then(response => {
                        parseCsvData(response.data);
                    })
                    .catch(error => console.error('Error fetching updated CSV data:', error));
            }
        };

        // socket.onclose = () => {
        //     console.log('WebSocket disconnected');
        // };

        // Cleanup on component unmount
        return () => {
            socket.send(JSON.stringify({ type: 'unsubscribe', vendor, timestamp, fileName }));
            socket.close();
        };
    }, [vendor, timestamp, fileName, axios, parseCsvData]); // Re-run on vendor or fileName change

    const handleHover = (data) => {
        if (data.points.length) {
            const hoveredTime = data.points[0].x;
            const croppedSegment = croppedSegments[hoveredTime];
            if (croppedSegments[hoveredTime]) {
                // Only update the image if a cropped segment exists at the hovered timestamp
                updateImage(croppedSegment);
            }
        }
    };

    const updateImage = (url) => {
        axios
            .get(`/assets/${vendor}/${url}`, { responseType: 'arraybuffer' }) // Specify arraybuffer to handle binary data
            .then(response => {
                const blob = new Blob([response.data], { type: 'image/png' });
                const imageUrl = URL.createObjectURL(blob);
                setImageSrc(imageUrl);
            })
            .catch(error => console.error('Error fetching asset:', error));
    };

    return (
        <Grid2 container>
            <Grid2 size={{ xs: 12, md: 9.5 }}>
                {chartData ? (
                    <ChartPlot
                        data={chartData.data}
                        layout={chartData.layout}
                        onHover={handleHover}
                    />
                ) : (
                    <p>Loading chart...</p>
                )}
            </Grid2>
            <Grid2 size={{ xs: 12, md: 2.5 }}>
                <div>
                    
                    {imageSrc && <img src={imageSrc} alt={imageSrc} style={{ display: 'block', maxWidth: '100%', width: '100%', height: 'auto' }} />}
                </div>
            </Grid2>
        </Grid2>
    );
};

export default ChartComponent;
