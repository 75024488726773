import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, ListItem, ListItemText } from '@mui/material';
import { useAuth } from './AuthProvider';

function VendorList() {
    const navigate = useNavigate();
    const { logs } = useAuth();
    const vendors = Object.keys(logs);

    useEffect(() => {
        if (vendors.length === 1) {
            navigate(`/${vendors[0]}`);
        }
    }, [vendors, navigate]);
    
    return (
        <List sx={{ cursor: 'pointer' }}>
            {vendors.map(vendor => (
                vendor ? (
                    <ListItem
                        key={vendor}
                        onClick={() => navigate(`/${vendor}`)}
                        sx={{
                            '&:hover': {
                                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                            },
                        }}>
                        <ListItemText primary={vendor} />
                    </ListItem>
                ) : null
            ))}
        </List>
    );
}

export default VendorList;
