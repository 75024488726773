import React from 'react';
import { AppBar, Button, Toolbar } from '@mui/material';
import BreadcrumbsComponent from './Breadcrumbs';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from './AuthProvider';

const Header = () => {
    const { logout } = useAuth();

    const onLogout = () => {
        logout();
        
    }
    return (
        <AppBar position="static">
            <Toolbar sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <BreadcrumbsComponent />
                {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ marginRight: '2rem' }}>
                        Log Viewer
                    </Typography> */}
                
                <Button color="white" sx={{paddingRight:0, minWidth:32}} onClick={onLogout}><LogoutIcon/></Button>
                {/* </Box> */}
            </Toolbar>
        </AppBar>
    );
}

export default Header;
