// src/AuthProvider.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import axios from 'axios';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [logs, setLogs] = useState([]); // State to store logs

    useEffect(() => {
        // Check if there's a token in sessionStorage on mount
        const token = sessionStorage.getItem('token');
        setIsAuthenticated(!!token);
        if (token) {
            fetchLogs(token); // Fetch logs if user is already logged in
        }
    }, []);

    const login = async (username, password) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/login`, { username, password });
            const token = response.data.token;
            sessionStorage.setItem('token', token); // Store token in sessionStorage
            setIsAuthenticated(true); // Update auth state
            await fetchLogs(token); // Fetch logs after setting authentication
        } catch (err) {
            throw new Error('Invalid username or password'); // Handle error (you can pass it to the Login component for display)
        }
    };

    const logout = () => {
        sessionStorage.removeItem('token');
        setIsAuthenticated(false);
        setLogs([]); // Clear logs on logout
    };

    const fetchLogs = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/files`, {
                headers: { Authorization: `Bearer ${token}` }
            });
            setLogs(response.data); // Store logs in state
        } catch (error) {
            console.error("Failed to fetch logs:", error);
        }
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, logs }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
