import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useAxios } from '../AxiosProvider';
import { useNavigate } from 'react-router-dom';

function DeleteLogButton({ vendor, timestamp, fileName, onDeleteSuccess }) {
    const axios = useAxios();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleDelete = async () => {
        try {
            // Call the API to delete the log
            await axios.delete(`/logs/${vendor}/${timestamp}/${fileName}`);
            if (onDeleteSuccess) onDeleteSuccess(fileName);

            handleClose();
        } catch (error) {
            console.error('Error deleting log:', error);
            handleClose();
        }
    };

    return (
        <>
            <Button
                variant="contained"
                color="error"
                // sx={{ margin: "6px 0 32px" }}
                onClick={handleOpen}
                sx={{ position: 'absolute', top: '14px', right: '64px' }}
            >
                Delete log
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="delete-log-dialog-title"
                aria-describedby="delete-log-dialog-description"
            >
                <DialogTitle id="delete-log-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <DialogContentText id="delete-log-dialog-description">
                        Are you sure you want to delete the log file `{fileName}`? This action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button
                        onClick={handleDelete}
                        color="error"
                        variant="contained"
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DeleteLogButton;
