import React from 'react';
import { Breadcrumbs, Link, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';

function BreadcrumbsComponent() {
    const navigate = useNavigate();
    const location = useLocation();
    const pathParts = location.pathname.split('/').filter(part => part);

    const vendor = pathParts[0];
    const timestamp = pathParts[1];
    const fileName = pathParts[2];

    const renderLink = (text, onClick) => (
        <Link
            color="inherit"
            onClick={onClick}
            sx={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}
        >
            {text}
        </Link>
    );

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{ color: 'white' }}>
            {renderLink('Home', () => navigate(`/`))}
            {vendor && renderLink(vendor, () => navigate(`/${vendor}`))}
            {timestamp && (
                <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>{timestamp}</Typography>
            )}
            {fileName && (
                <Typography sx={{ color: '#fff', fontWeight: 'bold' }}>{fileName}</Typography>
            )}
        </Breadcrumbs>
    );
}

export default BreadcrumbsComponent;
