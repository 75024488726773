import React, { createContext, useContext, useRef } from 'react';
import axios from 'axios';

// Create a context for the Axios instance
const AxiosContext = createContext(null);

// Axios provider component
export const AxiosProvider = ({ children, navigate }) => {
    // const { isAuthenticated, logout } = useAuth();
    const isRedirecting = useRef(false); // Ref to track if redirection is in progress
    const token = sessionStorage.getItem('token') || ''; // Ensure token is an empty string if not found

    // Create an Axios instance
    const axiosInstance = axios.create({
        baseURL: `${process.env.REACT_APP_API_URL}/api`, // Set your API base URL
        headers: {
            Authorization: token ? `Bearer ${token}` : undefined, // Include the token in the headers if it exists
        },
    });
    // Add a response interceptor
    axiosInstance.interceptors.response.use(
        response => response,
        error => {
            if (error.response) {
                if (error.response.status === 403 || error.response.status === 401) {
                    if (!isRedirecting.current) {
                        isRedirecting.current = true;
                        sessionStorage.removeItem('token');
                        window.location.reload();
                    }
                } else {
                    console.error(`Error ${error.response.status}: ${error.response.data}`);
                }
            } else {
                // Handle errors without response (e.g., network errors)
                if (!token) {
                    if (!isRedirecting.current) {
                        isRedirecting.current = true;
                        navigate('/login');
                    }
                } else {
                    console.error('Network error: ', error.message);
                }
            }
            return Promise.reject(error);
        }
    );
    

    return (
        <AxiosContext.Provider value={axiosInstance}>
            {children}
        </AxiosContext.Provider>
    );
};

// Custom hook to use the Axios context
export const useAxios = () => {
    const context = useContext(AxiosContext);
    if (!context) {
        throw new Error('useAxios must be used within an AxiosProvider');
    }
    return context;
};
