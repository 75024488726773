// src/components/FileList.js
import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import ChartComponent from './ChartComponent';
import { useAuth } from './AuthProvider';
import DeleteLogButton from './DeleteLogButton';

function FileList() {
    const { vendor, timestamp, fileName } = useParams();
    const { logs } = useAuth();
    const navigate = useNavigate();
    const timestampData = logs[vendor]?.find(entry => entry.timestamp === timestamp);
    const [files, setFiles] = useState(timestampData?.files || []);
    const handleChange = (event) => {
        const selected = event.target.value;
        navigate(`/${vendor}/${timestamp}/${selected}`);
    };
    const handleDelete = (fileName) => {
        const filteredFiles = files.filter(item => item !== fileName)
        setFiles(filteredFiles);
        navigate(`/${vendor}/${timestamp}`);
    }

    return (
        <>
            <FormControl variant="filled" fullWidth>
                <InputLabel>Select File</InputLabel>
                <Select
                    value={fileName ? fileName : ''}
                    onChange={handleChange}
                    label="Select File"
                    MenuProps={{
                        PaperProps: {
                            style: {
                                minWidth: "92%", // Set the minimum width here
                            },
                        },
                    }}
                >
                    {files.map(file => (
                        <MenuItem key={file} value={file} >
                            {file}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <DeleteLogButton vendor={vendor}
                timestamp={timestamp}
                fileName={fileName}
                onDeleteSuccess={handleDelete} />
            {fileName && <ChartComponent fileName={fileName} vendor={vendor} timestamp={timestamp} />}
        </>
    );
}

export default FileList;
