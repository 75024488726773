import React from 'react';
import Plot from 'react-plotly.js';

const ChartPlot = ({ data, layout, onHover }) => {
  return (
    <Plot
      data={data}
      layout={layout}
      style={{ width: '100%', height: '600px' }}
      config={{ responsive: true, displaylogo: false }}
      onHover={onHover}
    />
  );
};

export default ChartPlot;
