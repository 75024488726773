// src/components/ProtectedLayout.js
import React, { useState, useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const ProtectedLayout = () => {
    const { isAuthenticated } = useAuth();
    const [loading, setLoading] = useState(true); // Add a loading state to check if auth check is complete
    useEffect(() => {
        // Check if authentication is done
        if (isAuthenticated !== undefined) {
            setLoading(false);
        }
    }, [isAuthenticated]);

    // If loading, display a loading spinner or message
    if (loading) {
        return <div>Loading...</div>; // You can replace this with a spinner or some other UI
    }

    // If not authenticated, redirect to login page
    if (!isAuthenticated) {
        return <Navigate to="/login" replace />;
    }

    return <Outlet />; // Allow access to the protected route
};

export default ProtectedLayout;
