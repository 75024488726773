// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';
import { AxiosProvider } from './AxiosProvider';
import VendorList from './components/VendorList';
import TimestampList from './components/TimestampList';
import FileList from './components/FileList';
import Header from './components/Header';
import Login from './components/Login';
import { AuthProvider } from './components/AuthProvider';
import ProtectedLayout from './components/ProtectedLayout';

const AppWrapper = () => {
	const navigate = useNavigate(); // Get the navigate function
	return (
		<AuthProvider>
			<AxiosProvider navigate={navigate}>
				<Header />
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route element={<ProtectedLayout />}>
						<Route path="/" element={<VendorList />} />
						<Route path="/:vendor" element={<TimestampList />} />
						<Route path="/:vendor/:timestamp" element={<FileList />} />
						<Route path="/:vendor/:timestamp/:fileName" element={<FileList />} />
					</Route>
				</Routes>
			</AxiosProvider>
		</AuthProvider>
	);
};

function App() {
	return (
		<Router>
			<AppWrapper />
		</Router>
	);
}

export default App;
